<template>
  <v-dialog v-model="dialog" max-width="1000px" no-click-animation persistent>
    <v-card>
      <v-card-title>Site Level Authority Manager</v-card-title>
      <v-card-text class="pt-5">
        <v-row dense>
          <v-col cols="12" md="12">
            <v-alert outlined type="warning" text
              >This will overwrite any existing entries for the selected Location(s)</v-alert
            >
          </v-col>
        </v-row>
        <v-form ref="form" @submit="save">
          <v-row>
            <v-col cols="12" md="12" class="mb-0 pb-0">
              <v-select
                v-model="$v.form.roleId.$model"
                :items="slaTypes"
                item-text="label"
                item-value="value"
                label="Role"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <user-select
                v-model.trim="$v.form.userEmails.$model"
                :users="users"
                label="User"
                :noChips="!useChips"
                :max="max"
                saveEmail
                @handleUserSelect="handleUserSelect($event, 'user')"
              >
              </user-select>
            </v-col>
          </v-row>
          <h5>Locations</h5>
          <v-row dense>
            <v-col cols="12" md="3" class="checkbox">
              <v-checkbox v-model="selectAllLocations" label="All" @change="handleAllLocations"></v-checkbox>
            </v-col>
            <v-col cols="12" md="3" v-for="(location, i) in locations" :key="i" class="checkbox">
              <v-checkbox
                :value="location.id"
                v-model="$v.form.locationIds.$model"
                :label="location.name"
                @change="handleLocationChange"
                multiple
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Cancel </v-btn>
        <v-btn color="primary" @click="save()" :loading="saving" :disabled="saving"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { ASSIGN_LOCATION_ROLES } from '@/store/modules/User/actions';
import UserSelect from '@/components/UserSelect.vue';

const newManager = {
  roleId: '',
  userEmails: '',
  locationIds: [],
};

export default {
  name: 'LocationManager',
  inject: ['eventHub'],
  mixins: [validationMixin],
  components: { UserSelect },
  props: {},
  data() {
    return {
      dialog: false,
      saving: false,
      slaTypes: [
        { value: 10, label: 'Site Admin' },
        { value: 11, label: 'Site Admin - Read Only' },
        { value: 12, label: 'Site Admin - Reports Only' },
        { value: 13, label: 'Pays Invoices' },
        { value: 14, label: 'Schedules Vehicles (Owner)' },
        { value: 15, label: 'Schedules Special Needs Vehicles' },
        { value: 16, label: 'School Nurses' },
        { value: 17, label: 'Child Nutrition Notification Lunch Trips' },
      ],
      form: newManager,
      selectAllLocations: false,
    };
  },
  computed: {
    ...mapGetters('location', ['locations']),
    ...mapGetters('user', ['users']),
    useChips() {
      switch (this.$v.form.roleId.$model) {
        case 'siteAdmins':
          return true;
        case 'paysInvoices':
          return false;
        case 'childNutrition':
          return true;
        case 'vehicleOwner':
          return false;
        case 'vehicleOwnerSpecial':
          return false;
        case 'schoolNurse':
          return true;
        default:
          return false;
      }
    },
    max() {
      switch (this.$v.form.roleId.$model) {
        case 'siteAdmins':
          return 4;
        case 'paysInvoices':
          return 0;
        case 'childNutrition':
          return 4;
        case 'vehicleOwner':
          return 0;
        case 'vehicleOwnerSpecial':
          return 0;
        case 'schoolNurse':
          return 4;
        default:
          return 0;
      }
    },
  },
  validations: {
    form: {
      roleId: {},
      userEmails: {},
      locationIds: {},
    },
  },
  created() {},
  methods: {
    ...mapActions('user', [ASSIGN_LOCATION_ROLES]),
    async save() {
      if (
        !this.$v.form.roleId.$model ||
        !this.$v.form.userEmails.$model ||
        !this.$v.form.locationIds.$model ||
        !this.$v.form.locationIds.$model.length
      ) {
        this.$myalert.error('Please complete all fields');
        return;
      }
      try {
        this.saving = true;
        const r = await this.assignLocationRoles([
          {
            ...this.$v.form.$model,
            userEmails: [this.$v.form.userEmails.$model],
          },
        ]);
        if (r && r.done) {
          this.$emit('refresh');
          this.$myalert.success('Site Level Authority updated');
          this.dialog = false;
          this.resetForm();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
    close() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$v.form.roleId.$model = 0;
      this.$v.form.userEmails.$model = '';
      this.$v.form.locationIds.$model = [];
      this.selectAllLocations = false;
    },
    handleUserSelect(users) {
      this.$v.form.userEmails.$model = users;
      this.$v.form.$touch();
    },
    handleLocationChange(e) {
      if (this.$v.form.locationIds.$model.length == this.locations.length) this.selectAllLocations = true;
      else this.selectAllLocations = false;
      this.$v.form.locationIds.$touch();
    },
    handleAllLocations() {
      if (this.$v.form.locationIds.$model.length == this.locations.length) this.$v.form.locationIds.$model = [];
      else this.$v.form.locationIds.$model = this.locations.map((e) => e.id);
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
