<template>
  <v-dialog v-model="dialog" max-width="1000px" no-click-animation persistent>
    <v-card>
      <v-card-title>Assign Role to User</v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" @submit="save">
          <v-row>
            <v-col cols="12" md="12" class="mb-0 pb-0">
              <v-select
                v-model="$v.form.roleId.$model"
                :items="filteredRoles"
                item-text="name"
                item-value="id"
                label="Role"
                :readonly="!!value.roleId"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <template v-if="needsLocation">
            <h5>Locations</h5>
            <ComboBox
              v-model="selectedLocations"
              @input="handleLocationChange"
              show-select-all
              multiple
              label="Locations"
              :items="locationItems"
            />
          </template>
          <template v-if="needsFundingSource">
            <h5>Funding Sources</h5>
            <ComboBox
              v-model="selectedFundingSources"
              @input="handleFundingSourceChange"
              show-select-all
              multiple
              label="Funding Sources"
              :items="fundingSourceItems"
            />
          </template>
          <template v-if="$v.form.roleId.$model == 3">
            <v-row>
              <v-col cols="12" md="6">
                <v-checkbox
                  label="Allow access to Setup Tables"
                  v-model="userConfig.setupTables"
                  :value="userConfig.setupTables"
                  hide-details
                  class="mt-0"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-checkbox
                  label="Allow access to Settings"
                  v-model="userConfig.settings"
                  :value="userConfig.settings"
                  hide-details
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Cancel </v-btn>
        <v-btn dark color="success" @click="save()" :loading="saving" :disabled="saving"> Assign </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { GET_ROLES, ASSIGN_ROLES } from '@/store/modules/User/actions';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import { ROLES } from '@/shared/common';
import { ComboBox } from '@/components/shared';

const newRoleAssignment = {
  roleId: 0,
  locationIds: [],
  fundingSourceIds: [],
  userId: 0,
};

export default {
  name: 'AssignRole',
  inject: ['eventHub'],
  mixins: [validationMixin],
  components: { ComboBox },
  props: { value: Object },
  data() {
    return {
      dialog: false,
      saving: false,
      form: newRoleAssignment,
      selectAllLocations: false,
      selectAllFundingSources: false,
      userId: 0,
      userConfig: {
        setupTables: false,
        settings: false,
      },
      selectedLocations: [],
      selectedFundingSources: [],
    };
  },
  computed: {
    ...mapGetters('user', ['me', 'roles']),
    ...mapGetters('location', ['locations']),
    ...mapGetters('fundingSource', ['fundingSources']),
    locationItems() {
      return this.locations?.map((loc) => ({ text: loc.name, value: loc.id }));
    },
    fundingSourceItems() {
      return this.fundingSources?.map((loc) => ({ text: loc.name, value: loc.id }));
    },
    isRoleReadOnly() {
      return !!this.$v.form.roleId.$model;
    },
    filteredRoles() {
      let r = this.roles.filter((e) => e.id != ROLES.APPROVER);
      if (!this.me.is.superAdmin) r = r.filter((e) => e.id != ROLES.SUPER_ADMIN);
      return r;
    },
    needsLocation() {
      const rolesThatNeedLocation = [
        ROLES.SITE_ADMIN,
        ROLES.SITE_ADMIN_READ_ONLY,
        ROLES.SITE_ADMIN_REPORTS_ONLY,
        ROLES.SCHOOL_FINANCE,
        ROLES.VEHICLE_OWNER,
        ROLES.SPECIAL_NEEDS_VEHICLE_OWNER,
        ROLES.NURSE,
        ROLES.NUTRITIONIST,
        ROLES.REPORTS_ONLY,
        ROLES.SCHOOL_FINANCE,
      ];
      return rolesThatNeedLocation.includes(this.$v.form.roleId.$model);
    },
    needsFundingSource() {
      return this.$v.form.roleId.$model == ROLES.FUNDING_MANAGER;
    },
  },
  validations: {
    form: {
      roleId: {},
      locationIds: {},
      fundingSourceIds: {},
      userId: {},
      userEmail: {},
    },
  },
  methods: {
    ...mapActions('user', [ASSIGN_ROLES]),
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async save() {
      if (
        !this.$v.form.roleId.$model ||
        (this.needsLocation && (!this.$v.form.locationIds.$model || !this.$v.form.locationIds.$model.length)) ||
        (this.needsFundingSource &&
          (!this.$v.form.fundingSourceIds.$model || !this.$v.form.fundingSourceIds.$model.length))
      ) {
        this.$myalert.error('Please complete all fields');
        return;
      }
      try {
        this.saving = true;
        let toAssign = [];

        if (this.$v.form.locationIds.$model.length) {
          toAssign.push(
            ...this.$v.form.locationIds.$model.map((e) => ({
              userId: this.$v.form.userId.$model,
              userEmail: this.$v.form.userEmail.$model,
              roleId: this.$v.form.roleId.$model,
              locationId: e,
            }))
          );
        } else if (this.$v.form.fundingSourceIds.$model.length) {
          toAssign.push(
            ...this.$v.form.fundingSourceIds.$model.map((e) => ({
              userId: this.$v.form.userId.$model,
              userEmail: this.$v.form.userEmail.$model,
              roleId: this.$v.form.roleId.$model,
              fundingSourceId: e,
            }))
          );
        } else {
          toAssign.push({
            userId: this.$v.form.userId.$model,
            userEmail: this.$v.form.userEmail.$model,
            roleId: this.$v.form.roleId.$model,
          });
        }

        if (this.$v.form.roleId.$model == 3) {
          toAssign[0].setupTables = this.userConfig.setupTables;
          toAssign[0].settings = this.userConfig.settings;
        }
        const r = await this.assignRoles(toAssign);
        if (r && r.done) {
          this.$emit('refresh');
          this.$myalert.success('Role assigned');
          this.dialog = false;
          this.resetForm();
        }
      } catch (error) {
        console.log('e', error);
        this.$myalert.error(error);
      }
      this.saving = false;
    },
    close() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$v.form.roleId.$model = '';
      this.$v.form.locationIds.$model = [];
      this.$v.form.fundingSourceIds.$model = [];
      this.$v.form.userId.$model = 0;
      this.selectAllLocations = false;
    },
    handleLocationChange(e) {
      this.form.locationIds = this.selectedLocations.map((loc) => loc.value);
    },
    handleAllLocations() {
      if (this.$v.form.locationIds.$model.length == this.locations.length) this.$v.form.locationIds.$model = [];
      else this.$v.form.locationIds.$model = this.locations.map((e) => e.id);
    },
    handleFundingSourceChange(e) {
      this.form.fundingSourceIds = this.selectedFundingSources.map((loc) => loc.value);
    },
    handleAllFundingSources() {
      if (this.$v.form.fundingSourceIds.$model.length == this.fundingSources.length)
        this.$v.form.fundingSourceIds.$model = [];
      else this.$v.form.fundingSourceIds.$model = this.fundingSources.map((e) => e.id);
    },
  },
  watch: {
    value(v) {
      this.form = { ...newRoleAssignment, ...v };
      this.userConfig.setupTables = v.setupTables;
      this.userConfig.settings = v.settings;
      this.handleLocationChange();
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
