<template>
  <v-combobox
    v-model="displayUsers"
    :items="items"
    :label="label"
    hide-selected
    :multiple="multiple || max > 1"
    :hint="hint"
    :persistent-hint="showHint"
    outlined
    v-on:input="limiter"
    @change="handleChange"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            A user with that email hasn't been created yet. Press <kbd>enter</kbd> to add this email as a placeholder.
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="!noChips" v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip :input-value="selected" v-bind="attrs" label draggable>
        <span class="pr-2">
          {{ item ? item.text : '' }}
        </span>
        <v-icon @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'UserSelect',
  data() {
    return {
      displayUsers: [],
    };
  },
  props: {
    value: [Array, Number, String],
    label: String,
    hint: String,
    noChips: Boolean,
    max: Number,
    multiple: {
      type: Boolean,
      default: false,
    },
    users: Array,
    saveEmail: Boolean,
  },
  computed: {
    showHint() {
      return !!this.hint;
    },
    items() {
      return [
        { header: 'Select a user or enter an email address' },
        ...this.users.map((e) => ({ text: `${e.displayName} (${e.email})` })),
      ];
    },
  },
  created() {
    this.convertForDisplay();
  },
  methods: {
    convertForDisplay() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          this.displayUsers = this.value.map((e) => {
            if (Number.isInteger(e)) {
              const user = this.users.find((u) => u.id == e);
              return { text: `${user?.displayName ? user.displayName : 'Unregistered User'} (${user?.email})` };
            } else if (typeof e === 'string') {
              const user = this.users.find((u) => u.email == e);
              if (user) return { text: `${user.displayName} (${user.email})` };
              else return { text: e };
            } else return e;
          });
        } else {
          if (Number.isInteger(this.value)) {
            const user = this.users.find((u) => u.id == this.value);
            this.displayUsers = {
              text: `${user?.displayName ? user.displayName : 'Unregistered User'} (${user?.email})`,
            };
          } else if (typeof this.value === 'string') {
            const match = this.users.find((e) => e.email == this.value);
            if (match) this.displayUsers = { text: `${match.displayName} (${match.email})` };
            else this.displayUsers = { text: this.value };
          } else this.displayUsers = this.value;
        }
      } else this.displayUsers = this.max ? [] : '';
    },
    limiter(e) {
      if (this.max) {
        if (e.length > this.max) {
          this.$myalert.error(`You may only select up to ${this.max} ${this.label}`);
          e.pop();
        }
      }
    },
    handleChange() {
      if (Array.isArray(this.displayUsers)) {
        this.displayUsers = this.displayUsers.map((e) => (typeof e === 'string' ? { text: e } : e));
      } else if (typeof this.displayUsers === 'string') {
        this.displayUsers = { text: this.displayUsers };
      }
      if (this.displayUsers)
        this.$emit(
          'handleUserSelect',
          Array.isArray(this.displayUsers)
            ? this.displayUsers.map((e) => this.findUser(e))
            : this.findUser(this.displayUsers)
        );
      else if (this.displayUsers === null) this.$emit('handleUserSelect', null);
    },
    findUser({ text }) {
      const match = text.match(/\(([^)]+)\)/);
      const email = match ? match[1] : text;
      const found = this.users.find((e) => e.email == email);
      if (!this.saveEmail && found) return found.id;
      else return email;
    },
  },
  watch: {
    value() {
      this.convertForDisplay();
    },
  },
};
</script>
