<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="pl-0">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn v-if="canEditUser" class="float-right" color="green" dark @click="onSubmit"> save </v-btn>
          <v-btn v-if="canDeleteUser" class="float-right mr-2" color="red" dark @click="onRemove"> remove </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-form :readonly="!canEditUser">
        <v-container fluid>
          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.displayName"
                v-model.trim="$v.form.displayName.$model"
                label="Display Name"
                required
                :error-messages="handleErrors($v.form.displayName)"
                @blur="$v.form.displayName.$touch()"
                :disabled="!!value.id && !me.is.superAdmin"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.firstName"
                v-model.trim="$v.form.firstName.$model"
                label="First Name"
                required
                :error-messages="handleErrors($v.form.firstName)"
                @blur="$v.form.firstName.$touch()"
                :disabled="(found == 2 || !!value.id) && !me.is.superAdmin"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.lastName"
                v-model.trim="$v.form.lastName.$model"
                label="Last Name"
                required
                :error-messages="handleErrors($v.form.lastName)"
                @blur="$v.form.lastName.$touch()"
                :disabled="(found == 2 || !!value.id) && !me.is.superAdmin"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.email"
                v-model.trim="$v.form.email.$model"
                label="Email"
                required
                :error-messages="handleErrors($v.form.email)"
                @blur="$v.form.email.$touch()"
                @change="checkExist"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :error-messages="!$v.$dirty ? [] : $v.form.phone.minLength ? [] : ['Phone number must be 10 digits']"
                @blur="$v.form.phone.$touch()"
                :value="value.phone"
                v-model="form.phone"
                label="Phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="value.id && clientConfig.data.authentication !== 'tam'">
              <v-btn v-if="value.uuid != 'TEMP-NOT-REGISTERED'" color="primary" @click="resetUserPassword">
                Reset Password
              </v-btn>
              <v-btn v-else color="primary" @click="registerUser"> Check if Registered </v-btn>
            </v-col>
          </v-row>
          <v-row dense v-if="clientConfig.data.authentication !== 'tam'">
            <v-col cols="12" v-show="found === 0">
              <!-- A temporary password will be sent to this email when account is created -->
              This user will still need to register an account to gain access to the site.
            </v-col>
            <v-col cols="12" v-show="foundEmail == 2">
              <!-- This email is already registered with us. Original password and full name will be used. You can change
              them after saving. -->
              This email is already registered with us. They can use their existing credentials to access the site.
            </v-col>
            <v-col cols="12" v-show="foundEmail == 6"> This email is already registered. </v-col>
            <v-col cols="12" v-show="foundPhone == 8">
              This phone number is already registered with us. Original password and full name will be used. You can
              change them after saving.
            </v-col>
            <v-col cols="12" v-show="foundPhone == 24"> This phone number is already registered. </v-col>
          </v-row>
          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.yellowfinId"
                v-model.trim="$v.form.yellowfinId.$model"
                label="Yellowfin ID"
                @blur="$v.form.yellowfinId.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="canEditUser && value.uuid != 'TEMP-NOT-REGISTERED' && !usesRoster">
              <v-btn color="primary" @click="impersonate(value)"> Impersonate </v-btn>
            </v-col>
          </v-row>

          <v-row dense v-if="value.uuid == 'TEMP-NOT-REGISTERED' && clientConfig.data.authentication !== 'tam'">
            <v-col cols="12">
              <v-alert outlined type="warning" text>
                This user has not yet registered an account with this email address
              </v-alert>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="12">
              <h4>Roles</h4>
              <v-container>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Role</th>
                        <th class="text-left">Location(s)</th>
                        <th class="text-left">Funding Source(s)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in value.roles" :key="i">
                        <td
                          @click="
                            $emit('showAssignRole', {
                              userId: value.id,
                              userEmail: value.email,
                              roleId: item.roleId,
                              locationIds: item.locationIds,
                              fundingSourceIds: item.fundingSourceIds,
                              setupTables: item.setupTables,
                              settings: item.settings,
                            })
                          "
                        >
                          {{ item.name }}
                          <v-icon v-if="item.roleId == 3 && item.setupTables" color="primary" class="ml-2">
                            mdi-table
                          </v-icon>
                          <v-icon v-if="item.roleId == 3 && item.settings" color="primary" class="ml-2">
                            mdi-cog-outline
                          </v-icon>
                        </td>
                        <td
                          @click="
                            $emit('showAssignRole', {
                              userId: value.id,
                              userEmail: value.email,
                              roleId: item.roleId,
                              locationIds: item.locationIds,
                              fundingSourceIds: item.fundingSourceIds,
                              setupTables: item.setupTables,
                              settings: item.settings,
                            })
                          "
                        >
                          {{ getLocationList(item.locationIds) }}
                        </td>
                        <td
                          @click="
                            $emit('showAssignRole', {
                              userId: value.id,
                              userEmail: value.email,
                              roleId: item.roleId,
                              locationIds: item.locationIds,
                              fundingSourceIds: item.fundingSourceIds,
                              setupTables: item.setupTables,
                              settings: item.settings,
                            })
                          "
                        >
                          {{ getFundingSourceList(item.fundingSourceIds) }}
                        </td>
                        <td>
                          <v-icon
                            v-if="item.roleId != roleEnum.APPROVER && canEditUser"
                            color="red"
                            @click="removeRoleFromUser(item.roleId)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                v-if="canEditUser"
                color="primary"
                @click="$emit('showAssignRole', { userId: value.id, userEmail: value.email })"
                :disabled="!value.id"
              >
                Assign Role
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense class="mt-6">
            <v-col cols="12" md="12">
              <h4>Approver Roles</h4>
              <v-container class="px-0">
                <v-expansion-panels v-model="allPanels">
                  <v-expansion-panel v-for="(approver, index) of value.approver" :key="index">
                    <v-expansion-panel-header>
                      {{
                        approvalLevelsById[approver.approvalLevelId]
                          ? approvalLevelsById[approver.approvalLevelId].name
                          : 'New Approver Role'
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <approver-creator
                        :value="approver"
                        :userId="value.id"
                        :approvalLevels="approvalLevels"
                        :tripTypes="tripTypes"
                        :approverIndex="index"
                        :removeApprover="removeApprover"
                        :readonly="(!me.is.superAdmin && !me.is.transportationAdmin) || approver.cannotEdit"
                        :refresh="refresh"
                      ></approver-creator>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-btn v-if="canEditUser" color="primary" @click="addApprover()" class="mt-6" :disabled="!value.id">
                  Add Approver Role
                </v-btn>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ASSIGN_ROLES,
  REMOVE_ROLE,
  CHECK_USER_EXIST,
  RESET_PASSWORD,
  SAVE_USER,
  FIND_REGISTERED_USER,
} from '@/store/modules/User/actions';
import adminApi from '@/apis/admin';
import ApproverCreator from '@/components/ApproverCreator';
import { ROLES } from '@/shared/common';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';

const newUser = {
  displayName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  yellowfinId: null,
};

export default {
  mixins: [validationMixin],
  components: { ApproverCreator },
  props: {
    value: Object,
    roles: Array,
    approvalLevels: Array,
    tripTypes: Array,
    approvalLevelsById: Object,
    refreshUser: Function,
  },
  data() {
    return {
      allPanels: null,
      handleErrors,
      id: null,
      form: newUser,
      saved: false,
      found: null,
      foundEmail: null,
      foundPhone: null,
      roleEnum: ROLES,
    };
  },
  validations: {
    form: {
      displayName: { required },
      firstName: { required },
      lastName: { required },
      email: { required },
      phone: { minLength: minLength(14) },
      password: {},
      retype: {},
      yellowfinId: {},
    },
  },
  computed: {
    ...mapGetters('app', ['client', 'clientConfig']),
    ...mapGetters('user', ['me', 'rolesById']),
    ...mapGetters('location', ['locations', 'locationsById']),
    ...mapGetters('fundingSource', ['fundingSources', 'fundingSourcesById']),
    isExistingUser() {
      return !!this.id;
    },
    canEditUser() {
      if (this.me.is.superAdmin) {
        return true;
      }
      if (this.me.is.transportationAdmin || (this.me.is.limitedAdmin && this.me.data.limitedAdmin.setupTables)) {
        // can only impersonate non-superadmin
        return !this.value?.roles?.some((role) => role.roleId === 1);
      }

      return false;
    },
    canDeleteUser() {
      if (!this.isExistingUser) {
        return false;
      }
      if (this.me.id === this.form.id) {
        return false;
      }

      return this.me.is.superAdmin;
    },
    usesRoster() {
      const isEliteTier = this.clientConfig.data.modules.tier === 'elite';
      const usesRoster = this.clientConfig.data.modules.roster.sync;
      return isEliteTier && usesRoster;
    },
  },
  methods: {
    ...mapActions('user', [
      ASSIGN_ROLES,
      REMOVE_ROLE,
      CHECK_USER_EXIST,
      RESET_PASSWORD,
      SAVE_USER,
      FIND_REGISTERED_USER,
    ]),
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newUser;
        this.$emit('closeForm');
      }
    },
    async onRemove() {
      const ok = await this.$myconfirm('Are you sure you want to delete this User?');
      if (ok) {
        this.$emit('remove', this.id);
      }
    },
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model, userType: 'email' });
      this.saved = true;
    },
    addApprover() {
      this.value.approver.push({});
    },
    removeApprover(index) {
      this.value.approver = [
        ...this.value.approver.slice(0, index),
        ...this.value.approver.slice(index + 1, this.value.approver.length),
      ];
    },
    async assignRolesToUser() {
      try {
        const globalRoleIds = this.roles.filter((e) => e.global).map((e) => e.id);
        const r = await this.assignRoles({
          userId: this.value.id,
          roleIds: this.value.roles.filter((e) => globalRoleIds.includes(e)),
        });
        if (r) {
          this.$myalert.success('Roles Assigned');
          this.refresh();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async removeRoleFromUser(roleId) {
      const ok = await this.$myconfirm('Are you sure you want to remove this role from this user?');
      if (ok) {
        try {
          const r = await this.removeRole({ userEmail: this.value.email, roleId });
          if (r) {
            this.$myalert.success('Role removed');
            this.refresh();
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      }
    },
    async resetUserPassword() {
      const ok = await this.$myconfirm("Are you sure you want to reset this user's password?");
      if (ok) {
        const r = await this.resetPassword(this.value);
        if (r && r.done) this.$myalert.success("User's password has been reset & they have been notified");
        else this.$myalert.error("Unable to reset user's password");
      }
    },
    async registerUser() {
      try {
        const response = await this.findRegisteredUser(this.value);
        if (response?.found > 0) {
          await this.saveUser({ ...this.value, uuid: response.uuid });
          this.$myalert.success('User is registered');
          this.refresh();
        } else this.$myalert.error('This user is not registered');
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async impersonate(user) {
      const r = await adminApi.impersonateUser({ client: this.client, user });
      window.open('https://' + r.url, '_blank').focus();
    },
    async checkExist() {
      if (this.value.id > 0) return;
      const r = await this.checkUserExist({ email: this.$v.form.$model.email, phone: this.$v.form.$model.phone });
      this.found = r.found;
      this.foundEmail = r.found & 6;
      this.foundPhone = r.found & 24;
      if (r.found > 0 && (r.found & 20) == 0) {
        this.$v.form.$model.password = '1234qwer';
        this.$v.form.$model.retype = '1234qwer';
      } else {
        this.$v.form.$model.password = '';
        this.$v.form.$model.retype = '';
      }
      if (r.firstName) this.$v.form.$model.firstName = r.firstName;
      if (r.lastName) this.$v.form.$model.lastName = r.lastName;
    },
    refresh() {
      this.allPanels = null;
      this.refreshUser();
    },
    getLocationList(ids) {
      ids = ids || [];
      if (ids.length == this.locations.length) return 'All Locations';
      return ids.map((e) => this.locationsById[e]?.name).join(', ');
    },
    getFundingSourceList(ids) {
      ids = ids || [];
      if (ids.length == this.fundingSources.length) return 'All Funding Sources';
      return ids.map((e) => this.fundingSourcesById[e]?.name).join(', ');
    },
    compareValues() {
      if (!this.id && (this.$v.form.$model.email || this.$v.form.$model.displayName)) return true;
      if (!this.id) return false;
      if (
        this.$v.form.$model.displayName != this.value.displayName ||
        this.$v.form.$model.firstName != this.value.firstName ||
        this.$v.form.$model.lastName != this.value.lastName ||
        this.$v.form.$model.email != this.value.email ||
        this.$v.form.$model.phone != this.value.phone
      )
        return true;
      return false;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else this.form = { ...newUser, ...value };
      },
    },
    form: {
      immediate: true,
      deep: true,
      handler: function (form) {
        if (form.phone.length > 0) {
          let phone = form.phone.replace(/\D/g, '').substring(0, 10);
          const mask = phone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
          phone = !mask[2] ? mask[1] : '(' + mask[1] + ') ' + mask[2] + (mask[3] ? '-' + mask[3] : '');
          this.$nextTick(() => {
            form.phone = phone;
          });
        }
      },
    },
  },
};
</script>

<style scoped>
.checkboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
